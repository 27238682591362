<!-- 
  A series is a list of items with titles for each item.
  Items can be refering to assets and are selected with a dropdown.
-->
<template>
<div>
  <div class="m-2">
    <h2>Create Series</h2>
    <b-form-group label="Series Title:" label-for="series-title">
      <b-form-input id="series-title" v-model="seriesTitle"></b-form-input>
    </b-form-group>
    <b-form-group label="Add Item:" label-for="add-item">
      Content:
      <b-form-select id="add-item" v-model="itemContent">
        <b-form-select-option :value="null" disabled>-- Please select an asset --</b-form-select-option>
        <b-form-select-option v-for="asset in assets" v-bind:key="asset.id" :value="asset">
          {{ asset.name }}
        </b-form-select-option>
      </b-form-select>
      Public Name:
      <b-form-input id="add-item" v-model="itemName"></b-form-input>
      <b-button @click="addItemToList">Add</b-button>
    </b-form-group>
    <b-list-group>
      <b-list-group-item v-for="(item, index) in items" :key="index">
        {{ item.name }} : {{ item.content?.name }}
        <b-button @click="removeItemFromList(index)">Remove</b-button>
      </b-list-group-item>
    </b-list-group>
    <b-button @click="createSeries">Create</b-button>
  </div>
</div>
</template>

<script>
import { db } from '@/services/db';
import { getLog } from '@/services/log';
let log = getLog('CreateSeries');

export default {
  props: {
    assets: {
      type: Array,
      required: false,
    }
  },
  data() {
    return {
      seriesTitle: 'Untitled Series',
      itemName: null,
      itemContent: null,
      items: [],
    };
  },
  methods: {
    addItemToList() {
      this.items.push({
        name: this.itemName,
        content: this.itemContent,
    });
      this.itemName = null;
      this.itemContent = null;
    },
    removeItemFromList(index) {
      this.items.splice(index, 1);
    },
    createSeries() {
      log.log('createSeries', this.seriesTitle, this.items);
      // Save series to firestore db /AllSeries collection
      db.collection(`S2Videos`).add({
        name: this.seriesTitle,
        type: "series",
        ownerId: this.$store.account.uid,
        created: new Date(),
        filename: this.seriesTitle,
        items: this.items,
      });
    }
  }
};
</script>